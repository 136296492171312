<template>
<div>
<van-empty image="network" description="页面加载中..." v-if="loading" />
</div>
</template>

<script>
import { Empty } from 'vant';
import { setToken } from "@/utils/auth";
import { wapPay } from "@/api/unipay";

export default {
 data() {
     return {
       loading: true,
      // 请求参数
      submitParams: {
         appId: this.$route.query && this.$route.query.appId,
         orderId: this.$route.query && this.$route.query.orderId,
         txnAmt: this.$route.query && this.$route.query.txnAmt,
         currencyCode: this.$route.query && this.$route.query.currencyCode,
         orderDesc: this.$route.query && this.$route.query.orderDesc,
         frontUrl: this.$route.query && this.$route.query.frontUrl,
         backUrl: 'http://localhost:8080/open/union/1.0/backendNotifyForCMCC/',
         payTimeout: this.$route.query && this.$route.query.payTimeout
      }
     };
   },
  components: {
    [Empty.name]: Empty
  },
  created() {
     this.onInit();
     //this.onSubmit();
  },
  methods: {
      // 初始化
      onInit() {
        if(this.submitParams.appId === undefined) {
           return this.$router.push({name:'pay-error',query:{ respCode: 500, respMsg: 'appId未提供'}});
        }
        if(this.submitParams.txnAmt === undefined) {
           return this.$router.push({name:'pay-error',query:{ respCode: 500, respMsg: '金额未提供'}});
        }
        setToken(this.submitParams.appId);
        this.submitParams.orderId = this.submitParams.appId  + this.getDate() +  Math.floor(Math.random() * 9999999999),
        //console.log("orderId=" + this.submitParams.orderId);
        this.submitParams.backUrl = this.submitParams.backUrl + this.submitParams.orderId;
      },
      // 手机支付
      onSubmit() {
        console.log("submit");
        //this.loading = false;
        wapPay(this.submitParams).then(response => {
            if(response.code == 200 && response.data.html != undefined) {
                 return document.write(response.data.html);
            } else {
                 return this.$router.push({name:'pay-error',query:{ respCode: response.code, respMsg: response.msg}});
            }
        });
      },
    // 获取当前时间
    getDate() {
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month > 9 ? month : '0' + month;;
      day = day > 9 ? day : '0' + day;
      return `${year}${month}${day}`;
    },
  }
};

</script>
